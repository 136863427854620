import * as React from "react";
import styled from "styled-components";

const Header = ({ isNews }) => (
  <>
    <div className="navContentSpacerHelperSingleNavBar"></div>
    <header className="navWrapper">
      <nav className="mainNav singleNavBar">
        <div className="mainNavBackgroundHelper"></div>
        <div className="logo">
          <a href="/">
            <img
              src="/assets/images/logos/wigasoft-logo.svg"
              alt="WigaSoft Logo"
              width="175"
            />
          </a>
        </div>
        <div className="mainNav-opener"></div>
        <ul className="mainLinks">
          <li className={isNews ? "is-active" : ""}>
            <a href="/news/">News</a>
          </li>
          <li>
            <a href="/dokumentationsloesungen/heime/">Heime</a>
          </li>
          <li>
            <a href="/dokumentationsloesungen/spitaeler/">Spitäler</a>
          </li>
          <li>
            <a href="/dokumentationsloesungen/rehabilitation/">
              Rehabilitation
            </a>
          </li>
          <li>
            <a href="/portrait/">Über uns</a>
          </li>
          <li>
            <a href="/support/">Support</a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/wigasoft-ag">
              <div className="linkedInIcon" />
            </a>
          </li>
        </ul>
      </nav>
    </header>
  </>
);

export default Header;
