export const spacers = {
  spacerSidesMobile: 22.5, //16.18,
  spacerMobile: 45,
  spacerPC: 90,
};

export const breakpoints = {
  pageWidthTabletVert: 768, //pageWidthMin
  pageWidthTabletHor: 1024, //pageWidthNormal
  pageWidthPC: 1280, //pageWidthMax
  pageWidthPCXL: 1920,
};
