import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";

interface PaginationLinkProps {
  index: number;
  activeChunk: number;
  setActiveChunk: React.Dispatch<React.SetStateAction<number>>;
}

const PaginationDiv = styled.a`
  width: 35px;
  height: 35px;
  background: #a9acaf;
  color: white;
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;

  &:hover,
  &.active {
    background: #ff6a39;
    color: white;
  }

  @media screen AND (min-width: ${breakpoints.pageWidthPC}px) {
    width: 45px;
    height: 45px;
  }
`;

const PaginationLink: React.FC<PaginationLinkProps> = ({
  index,
  activeChunk,
  setActiveChunk,
}) => (
  <PaginationDiv
    href={`#${index}`}
    className={activeChunk === index ? "pagination active" : "pagination"}
    onClick={() => {
      setActiveChunk(index);
      setTimeout(() => {
        window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
      }, 50);
    }}
  >
    {index + 1}
  </PaginationDiv>
);

export default PaginationLink;
