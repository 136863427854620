import React from "react";
import styled from "styled-components";
import { breakpoints, spacers } from "../styles/variables";

import NewsPrev from "./NewsPrev";

interface NewsGridProps {
  entries: [
    {
      title: string;
      url: string;
      uri: string;
      id: string;
      postDate: string;
      prevtext: [
        {
          text: string;
        }
      ];
      prevtitel: [
        {
          titel: string;
        }
      ];
      prevlead: [
        {
          lead: string;
        }
      ];
    }
  ];
}

const GridDiv = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    margin-bottom: ${spacers.spacerSidesMobile}px;
  }
  @supports (display: grid) {
    > div {
      margin-bottom: 0;
    }
    display: grid;
    grid-gap: ${spacers.spacerSidesMobile}px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-auto-rows: 10px; //restricts row height, change later

    @media screen and (min-width: ${breakpoints.pageWidthTabletVert}px) {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-column-gap: ${spacers.spacerMobile}px;
    }

    @media screen and (min-width: ${breakpoints.pageWidthTabletHor}px) {
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }

    @media screen and (min-width: ${breakpoints.pageWidthPC}px) {
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
      grid-column-gap: ${spacers.spacerPC}px;
    }
  }
`;

//move newsquery here?
const NewsGrid: React.FC<NewsGridProps> = ({ entries }) => {
  return (
    <>
      <GridDiv className="grid has-padding-top has-padding-bottom">
        {entries !== undefined
          ? entries.map((entry) => {
              return <NewsPrev key={entry.id} data={entry} />;
            })
          : ""}
      </GridDiv>
    </>
  );
};

export default NewsGrid;
