import React, { useEffect, useState, useLayoutEffect } from "react";
import { useMeasure } from "react-use";
import { Link } from "gatsby";

interface NewsPrevProps {
  data: {
    title: string;
    url: string;
    uri: string;
    id: string;
    postDate: string;
    prevtext: [
      {
        text: string;
      }
    ];
    prevtitel: [
      {
        titel: string;
      }
    ];
    prevlead: [
      {
        lead: string;
      }
    ];
  };
}

interface Titel {
  titel: string;
}

interface Lead {
  lead: string;
}

interface Text {
  text: string;
}

const NewsPrev: React.FC<NewsPrevProps> = ({ data }) => {
  //const previewRef = useRef(null);
  const [titel, setTitel] = useState<Titel | undefined>(undefined);
  const [lead, setLead] = useState<Lead | undefined>(undefined);
  const [text, setText] = useState<Text | undefined>(undefined);
  const [previewRef, { height }] = useMeasure();

  const rowHeight = 10;
  const rowGap = 22.5;

  const getRowSpan = () => Math.ceil((height + rowGap) / (rowHeight + rowGap));

  const date = new Date(data.postDate);
  const dateOptions: object = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };

  const [rowSpan, setRowSpan] = useState(getRowSpan());

  useEffect(() => {
    setTitel(data.prevtitel.find((obj) => obj.titel));
    setLead(data.prevlead.find((obj) => obj.lead));
    setText(data.prevtext.find((obj) => obj.text));
  }, []);

  useLayoutEffect(() => {
    setRowSpan(getRowSpan);
  }, [height]);

  return (
    <div
      className="news-item item blog"
      style={{ gridRowEnd: `span ${rowSpan}` }}
    >
      <div className="content" ref={previewRef}>
        <Link to={"/" + data.uri}>
          <h4 className="is-color">
            {titel != undefined ? titel.titel : data.title}
          </h4>
          <p className="more-arrow">
            <span className="articleLead">
              {lead != undefined ? lead.lead : ""}
            </span>
            &nbsp;
            {text != undefined ? text.text : ""}
          </p>
          <h6 className="articleDate padding-7-top">
            News / {date.toLocaleDateString("de-CH", dateOptions)}
          </h6>
        </Link>
      </div>
    </div>
  );
};

export default NewsPrev;
