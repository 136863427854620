import React from "react";
import styled from "styled-components";

const HeaderDiv = styled.div`
  width: 100%;
  height: 24vh;
  max-height: 240px;
  background: url("https://wigasoft-prod.imgix.net/backgrounds/bkg-wigasoft.png?ch=Width,DPR&auto=compress,format&cs=strip&w=2560&h=400&fit=crop&flip=h");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen AND (max-width: 1023px) {
    > div {
      width: 100%;
    }
  }

  h1 {
    color: white;
  }
`;

const NewsArchivHeading = () => (
  <HeaderDiv className="headerDivNews">
    <div className="wrapper has-padding-top has-padding-bottom">
      <h1 className="h1">News</h1>
    </div>
  </HeaderDiv>
);

export default NewsArchivHeading;
