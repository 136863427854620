import React, { useState, useEffect } from "react";
import useLocation from "react-use/esm/useLocation";
import { graphql, useStaticQuery } from "gatsby";
import chunk from "lodash/chunk";

import DefaultLayout from "../../layouts/default";
import NewsGrid from "../../components/NewsGrid";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PaginationLink from "../../components/PaginationLink";
import NewsArchivHeading from "../../components/NewsArchivHeading";

const News = () => {
  const data = useStaticQuery(graphql`
    query {
      cms {
        entries(orderBy: "postDate desc") {
          title
          url
          uri
          id
          postDate
          ... on CMS_news_news_Entry {
            prevtitel: matrixNewsPreview {
              ... on CMS_matrixNewsPreview_titel_BlockType {
                titel
              }
            }
            prevlead: matrixNewsPreview {
              ... on CMS_matrixNewsPreview_lead_BlockType {
                lead
              }
            }
            prevtext: matrixNewsPreview {
              ... on CMS_matrixNewsPreview_text_BlockType {
                text
              }
            }
          }
          ... on CMS_news_news1212_Entry {
            prevtitel: matrixNewsPreview {
              ... on CMS_matrixNewsPreview_titel_BlockType {
                titel
              }
            }
            prevlead: matrixNewsPreview {
              ... on CMS_matrixNewsPreview_lead_BlockType {
                lead
              }
            }
            prevtext: matrixNewsPreview {
              ... on CMS_matrixNewsPreview_text_BlockType {
                text
              }
            }
          }
          ... on CMS_news_news1323_Entry {
            prevtitel: matrixNewsPreview {
              ... on CMS_matrixNewsPreview_titel_BlockType {
                titel
              }
            }
            prevlead: matrixNewsPreview {
              ... on CMS_matrixNewsPreview_lead_BlockType {
                lead
              }
            }
            prevtext: matrixNewsPreview {
              ... on CMS_matrixNewsPreview_text_BlockType {
                text
              }
            }
          }
          ... on CMS_news_news1434_Entry {
            prevtitel: matrixNewsPreview {
              ... on CMS_matrixNewsPreview_titel_BlockType {
                titel
              }
            }
            prevlead: matrixNewsPreview {
              ... on CMS_matrixNewsPreview_lead_BlockType {
                lead
              }
            }
            prevtext: matrixNewsPreview {
              ... on CMS_matrixNewsPreview_text_BlockType {
                text
              }
            }
          }
        }
      }
    }
  `);

  const newsPaginated = chunk(data.cms.entries, 10);
  const { hash } = useLocation();
  const startChunk = hash ? hash.split("#")[1] : "0";
  const [activeChunk, setActiveChunk] = useState(parseInt(startChunk));

  return (
    <DefaultLayout title="News">
      <Header isNews />
      <NewsArchivHeading />
      <div className="wrapper has-padding-top has-padding-bottom home">
        <NewsGrid entries={newsPaginated[activeChunk]} />
      </div>
      <div
        className="has-padding-bottom"
        style={{
          textAlign: "center",
        }}
      >
        {newsPaginated.map((item, index) => (
          <PaginationLink
            activeChunk={activeChunk}
            setActiveChunk={setActiveChunk}
            index={index}
            key={index}
          />
        ))}
      </div>
      <Footer />
    </DefaultLayout>
  );
};

export default News;
